;( function ( $ ) {
    // transition effects based on scroll position
    $( window ).on( 'load resize scroll', function(e) {
        var scrollPosition = $( document ).scrollTop();
        if ( scrollPosition > 150 ) {
            $( '#masthead' ).addClass( 'shrinked' );
        } else if ( scrollPosition < 25 ) {
            $( '#masthead' ).removeClass( 'shrinked' );
        }
    } );

    // dropdown slide animations
    $( '.dropdown' ).on( 'show.bs.dropdown', function( e ) {
        $( this ).find( '.dropdown-menu' ).first().stop( true, true ).slideDown( 300 );
    } );
    $( '.dropdown' ).on( 'hide.bs.dropdown', function( e ) {
        $( this ).find( '.dropdown-menu' ).first().stop( true, true ).slideUp( 200 );
    } );

    // Select 2
    $( '.select2' ).select2( {
        dropdownPosition: 'below',
        // allowClear: false
    } );

    // show collapse when anchorlink clicked
    let anchorlinks = $( '.jumbotron a[href^="#"]' );
    for ( let anchorlink of anchorlinks ) {
        anchorlink.addEventListener( 'click', ( e ) => {
            e.preventDefault();
            let hash = anchorlink.getAttribute( 'href' );
            window.location.hash = hash;
            let item = anchorlink.getAttribute( 'data-target-collapse' );
            $( item ).collapse( 'show' ).on('shown.bs.collapse', function () {
                $( 'a[name="' + hash.replace( '#', '' ) + '"]' )[0].scrollIntoView();
            } );
        } );
    }

} ( jQuery ) );